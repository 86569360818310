import {
  Banner,
  BannerAdd,
  BannerDetail,
  BannerType,
  BreakingNews,
  BreakingNewsAdd,
  EditSystemUserList,
  EditUserList,
  MainCategory,
  MainCategoryAdd,
  MainCategoryDetail,
  News,
  NewsAdd,
  NewsDetail,
  Settings,
  SystemUserList,
  UserList,
  VideoNews,
  VideoNewsAdd,
  VideoNewsDetail,
  Dashboard,
  Role,
  HomeCategory,
  Travel,
  TravelAdd,
  AboutUs,
} from "modules/admin";
import AllSettings from "modules/admin/settings/all";
import TravelDetail from "modules/admin/travel_detail";
import { Link } from "react-router-dom";

export default [
  {
    name: "Хянах самбар",
    path: "/dashboard",
    element: <Dashboard />,
    
  },
  {
    name: "Мэдээ",
    path: "/news",
    element: <News />,
    
  },
  {
    name: "Мэдээ нэмэх",
    path: "/news/add",
    element: <NewsAdd />,
    
  },
  {
    name: "Мэдээ дэлгэрэнгүй",
    path: "/news/:newsId",
    element: <NewsDetail />,
    
  },
  {
    name: "Системийн Хэрэглэгчид",
    path: "/systemuser",
    element: <SystemUserList />,
    
  },
  {
    name: "Системийн Хэрэглэгчид засах",
    path: "/systemuser/:newsId",
    element: <EditSystemUserList />,
    
  },
  {
    name: "Хэрэглэгчид",
    path: "/users",
    element: <UserList />,
    
  },
  {
    name: "Хэрэглэгчид засах",
    path: "/users/:newsId",
    element: <EditUserList />,
    
  },
  {
    name: "Banner Type",
    path: "/bannertype",
    element: <BannerType />,
    
  },
  {
    name: "Banner",
    path: "/banner",
    element: <Banner />,
    
  },
  {
    name: "Banner засах",
    path: "/banner/:bannerId",
    element: <BannerDetail />,
    
  },
  {
    name: "Banner нэмэх",
    path: "/banner/add",
    element: <BannerAdd />,
    
  },
  {
    name: "Role",
    path: "/roles",
    element: <Role />,
    
  },
  {
    name: "Home Categories",
    path: "/homecategories",
    element: <HomeCategory />,
  },
  {
    name: "Travel",
    path: "/travel",
    element: <Travel />,
  },
  {
    name: "Travel add",
    path: "/travel/add",
    element: <TravelAdd />,
  },
  {
    name: "Travel detail",
    path: "/travel/:id",
    element: <TravelDetail />,
  },
  {
    name: "About us",
    path: "/about-us",
    element: <AboutUs />,
  },
];
