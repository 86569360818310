import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage'
import { initializeFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB5EwCoZFirJrUO87IwIb9-K9EsWtv9Q5Q",
  authDomain: "m-trip-cff7b.firebaseapp.com",
  projectId: "m-trip-cff7b",
  storageBucket: "m-trip-cff7b.appspot.com",
  messagingSenderId: "430926259596",
  appId: "1:430926259596:web:994a120321e0fafa45e2a9",
  measurementId: "G-WJ2X8DHKQ7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = initializeFirestore(app, { experimentalForceLongPolling: true, useFetchStreams: false })
export const auth = getAuth(app);
export const storage = getStorage(app)

export default app;